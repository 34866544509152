import loginLogo from '../Resource/Images/loginlogo.svg';

export const commonConfig = {
  apiUrl: process.env.REACT_APP_API_URL,
  ngsUrl: process.env.REACT_APP_NGS_URL,
  vnpLogo: loginLogo,
  CompanyName: 'VNP',
  companyNameSmall: 'Vnp',
  VNPaddress: 'Vnp Transport 3590 Rue Griffith. Saint-Laurent,Quebec. H4T 1A7',
  country: 'Canada',
  vnpContactNumber: '514-312-1101',
  vnpEmail: 'nemish@vnptransport.ca',
  vnpWebsite: 'www.vnptransport.com',
  copyrightText: `© ${new Date().getFullYear()} VNP Transport. All rights reserved.`,
  CUSTOMER_ONTIME_ID: 'bab3264a-05ae-4deb-b596-0af606b67d19',
  redirectContactUsURL: 'https://vnptransport.ca/contactus',
  redirectToHome: 'https://vnptransport.ca/',
  centerLatLong: { lat: 45.508888, lng: -73.561668 },
  weightUnit: 'lb',
  HWLUnit: 'in',
  publishableKey: process.env.REACT_APP_STRIPE_KEY,
};
